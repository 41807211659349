<template>
  <div>
    <router-view />
  </div>
</template>

<script>
// import LayoutFull from '@core/layouts/layout-full/LayoutFull.vue'

export default {
  components: {
    // LayoutFull,
  },
}
</script>
